$fontSize: 17px;
$largeFontSize: 22px;
$font-family: 'Roboto', 'Avenir', 'Arial', sans-serif;
$font-family-medium: 'Roboto Medium', 'Avenir Medium', 'Arial Medium', sans-serif;
$font-family-bold: 'Roboto Bold', 'Avenir Bold', 'Arial Bold', sans-serif;

* {
  box-sizing: border-box;
  outline: none;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: auto;
  overflow: auto;
  font-size: $fontSize;
}

.leaderBoardContainer {
  width: 1920px;
  height: 1080px;
  margin: auto;
  overflow: hidden;
  font-size: $fontSize;
  background-image: url('../assets/leaderBoardBackground.png');
  background-size: contain;
  background-repeat: no-repeat;

  .qr-code {
    padding: 20px 30px 40px 30px;
    position: absolute;
    bottom: -20px;
    right: 55px;
    border: 3.5px solid #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px #df35e3 inset, 0px 0px 10px 5px #df35e3;
    background-color: transparent;
  }
}

body {
  margin: 0;
  font-family: $font-family;
  font-size: $fontSize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-padding {
  padding: 0 !important;
}

.flex {
  display: flex;
  flex: 1 1 auto;
  position: relative;

  .direction-column {
    flex-direction: column;
  }
}

.flex-center {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
  align-items: center;
}

.direction-column {
  flex-direction: column;
}

.flex.wrapper {
  height: 100%;
  width: 100%;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}

.branded {
  background-image: url('../assets/brandedBG.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100%;
  color: #ffffff;
}

.unbranded {
  background-image: url('../assets/unbrandedBG.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100%;
  color: #ffffff;
}

.team-name-img {
  width: 80%;
  margin: 20px auto 10px auto;
}

.branded-button-inner-text {
  position: absolute;
  font-size: 3.2vh;
  font-weight: bold;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name-comp-wrapper {
  background-image: url('../assets/unbrandedBG.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  color: #ffffff;
}

.name-comp-team-logo {
  // justify-self: center;
  // margin-top: 2rem;
  // margin-bottom: 4.5rem;
  // max-width: 90%;
  text-align: center;
  padding-top: 20px;

  img {
    width: 100%;
  }
}

.name-comp-input-wrapper {
  background-image: url('../assets/nameInputBG.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-comp-input {
  padding: 14px;
  font-size: 24px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.name-comp-btn {
  // padding: 5px;
  font-size: 16px;
  width: 80%;
  margin-top: 40px;
  cursor: pointer;
}
.name-comp-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inbetween-screens-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.inbetween-screens-img {
  width: 100%;
}

.question-wrapper {
  // background-color: #323f54;
  // min-height: 180px;
  // opacity: 0.9;
  background: url('../assets/questionBG.png');
  background-size: 100% 100%;
  padding: 30px;
  margin-top: 10px;

  .question-text {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.answer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 80px;
}

.answer-button {
  background-image: url('../assets/answerButtonBG.png');
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 95%;
  height: 4rem;

  .answer-button-inner-letter {
    width: 25%;
    height: 100%;
    font-weight: 900;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .answer-button-inner-text {
    font-size: 1rem;
    // line-height: 1;
    // font-weight: 600;
    width: 80%;
    height: 100%;
    text-align: left;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
}

.unbranded-correct-button {
  background: url('../assets/answerButtonUnbrandedCorrect.png') !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  box-shadow: 0 0 10px #fff, 0 0 5px #fff, 0 0 10px #009891, 0 0 15px #009891, 0 0 20px #009891, 0 0 25px #009891,
    0 0 30px #009891;
  border-radius: 0 10px 10px 0;
}

.branded-correct-button {
  background: url('../assets/answerButtonBrandedCorrect.png') !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  box-shadow: 0 0 10px #fff, 0 0 5px #fff, 0 0 10px #e176e4, 0 0 15px #e176e4, 0 0 20px #e176e4, 0 0 25px #e176e4,
    0 0 30px #e176e4;
  border-radius: 0 10px 10px 0;
}

.incorrect-button {
  background: url('../assets/answerButtonIncorrect.png') !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  box-shadow: 0 0 10px #fff, 0 0 5px #fff, 0 0 10px #e60073, 0 0 15px #e60073, 0 0 20px #e60073, 0 0 25px #e60073,
    0 0 30px #e60073;
  border-radius: 0 10px 10px 0;
  // height: 35%;
}

.brandedFooter {
  position: fixed;
  bottom: -14px;
  padding: 4px 0;
  width: 100%;
  text-align: center;
  line-height: 1;
  font-size: 0.9rem;
  background-color: rgb(56, 97, 136);
  border-radius: 20px 20px 0 0;
}

.teamLeaderBoard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .leaderBoardTitle {
    margin-bottom: 80px;

    img {
      height: auto;
      width: 473px;
    }
  }

  .teamNames {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    gap: 187px;

    .team1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 176px;
      width: 541px;
      border: 3.5px solid #fff;
      border-radius: 30px;
      text-align: center;
      box-shadow: 0px 0px 10px 5px #df35e3 inset, 0px 0px 10px 5px #df35e3;
      background-color: transparent;
    }

    .team2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 176px;
      width: 541px;
      border: 3.5px solid #fff;
      border-radius: 30px;
      text-align: center;
      box-shadow: 0px 0px 10px 5px #00b7d9 inset, 0px 0px 10px 5px #00b7d9;
      background-color: transparent;
    }

    img {
      height: 50px;
    }
  }

  .teamScore {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.topPlayers {
  margin-top: 20px;
  width: 1368px;

  .top3 {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;

    .first,
    .second,
    .third {
      text-align: center;
      width: 456px;

      .box.teamundefined {
        border-top: 18px solid #efefef;
      }
      .box.team1 {
        border-top: 18px solid #df35e3;
      }
      .box.team2 {
        border-top: 18px solid #00b7d9;
      }
    }

    .first .box {
      background-color: #ffffff;
      height: 157px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .second .box {
      background-color: #ffffff;
      height: 107px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .third .box {
      background-color: #ffffff;
      height: 77px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h2 {
      color: #184194;
      font-weight: 600;
      font-size: 67.43px;
      letter-spacing: 0.025em;
      margin: 0;
    }

    h3 {
      color: #ffffff;
      font-weight: 600;
      font-size: 51.87px;
      letter-spacing: 0.025em;
      margin: 0;
    }

    h4 {
      color: #ffffff;
      font-weight: 400;
      font-size: 32.42px;
      letter-spacing: 0.025em;
      margin: 0;
    }
  }

  .bottom3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 100px;

    h3 {
      color: #ffffff;
      font-weight: 600;
      font-size: 32.42px;
      line-height: 85.58px;
      letter-spacing: 0.025em;
    }

    .teamundefined {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 66px;
      width: 404px;
      border: 3.5px solid #fff;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0px 0px 10px 5px #efefef inset, 0px 0px 10px 5px #efefef;
      background-color: transparent;
    }
    .team1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 66px;
      width: 404px;
      border: 3.5px solid #fff;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0px 0px 10px 5px #df35e3 inset, 0px 0px 10px 5px #df35e3;
      background-color: transparent;
    }
    .team2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 66px;
      width: 404px;
      border: 3.5px solid #fff;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0px 0px 10px 5px #00b7d9 inset, 0px 0px 10px 5px #00b7d9;
      background-color: transparent;
    }
  }
}
